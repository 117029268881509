<template>
    <div>
        <div class='CreateAndEdit'>
            <el-card class="box-card">
                <div>
                    <i class="el-icon-close cha" @click="$router.go(-1)"></i>
                    <div class="clearfix">
                        仪器仪表位置更换
                    </div>
                </div>

                <el-divider></el-divider>
                <div class="formbox">
                    <el-form :model="chgLocalInfo" :rules="rules" ref="chgLocalInfo">
                        <h1>仪器1信息</h1>
                        <el-divider></el-divider>
                        <el-form-item label="仪器条码" :label-width="formLabelWidth" prop="deviceOneCode" >
                            <!-- <el-input v-model="chgLocalInfo.deviceOneCode" autocomplete="off" class="input-lenght"></el-input>
                        <span style="color: red;">(扫码录入)</span> -->
                            <el-input ref="auxCodeRef" v-model="chgLocalInfo.deviceOneCode"
                                @keydown.enter.native="courierOneEnterDown" @keyup.enter.native="courierOneEnterUp"
                                placeholder="请输入标签查询" autocomplete="off" style="width: 350px;" ></el-input>
                            <span style="color: red; margin-left: 10px;">(扫码录入)</span>
                        </el-form-item>
                        <el-form-item label="仪器名称" :label-width="formLabelWidth" prop="deviceOneName" class="input-lenght">
                            <el-input v-model="chgLocalInfo.deviceOneName" autocomplete="off" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="所在库房" :label-width="formLabelWidth" prop="oneOldWhCodeName"
                            class="input-lenght">
                            <el-input v-model="chgLocalInfo.oneOldWhCodeName" autocomplete="off" disabled></el-input>
                        </el-form-item>
                        <el-form-item label='保存位置' :label-width="formLabelWidth" prop="oneOldLocal" class="input-lenght">
                            <el-input v-model="chgLocalInfo.oneOldLocal" autocomplete="off" disabled></el-input>
                        </el-form-item>
                        <h1>仪器2信息</h1>
                        <el-divider></el-divider>
                        <el-form-item label="仪器条码" :label-width="formLabelWidth" prop="deviceTwoCode">
                            <!-- <el-input v-model="chgLocalInfo.deviceTwoCode" autocomplete="off"
                            class="input-lenght"></el-input><span style="color: red;">(扫码录入)</span> -->
                            <el-input v-model="chgLocalInfo.deviceTwoCode" @keydown.enter.native="courierTwoEnterDown"
                                @keyup.enter.native="courierTwoEnterUp" placeholder="请输入标签查询" autocomplete="off"
                                style="width: 350px;"></el-input>
                            <span style="color: red; margin-left: 10px;">(扫码录入)</span>
                        </el-form-item>
                        <el-form-item label="仪器名称" :label-width="formLabelWidth" prop="deviceTwoName" class="input-lenght">
                            <el-input v-model="chgLocalInfo.deviceTwoName" autocomplete="off" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="所在库房" :label-width="formLabelWidth" prop="twoOldWhCodeName"
                            class="input-lenght">
                            <el-input v-model="chgLocalInfo.twoOldWhCodeName" autocomplete="off" disabled></el-input>
                        </el-form-item>
                        <el-form-item label='保存位置' :label-width="formLabelWidth" prop="twoOldLocal" class="input-lenght">
                            <el-input v-model="chgLocalInfo.twoOldLocal" autocomplete="off" disabled></el-input>
                        </el-form-item>
                        <h1>更换信息</h1>
                        <el-divider></el-divider>
                        <el-form-item label="更换日期" :label-width="formLabelWidth" prop="ghDate">
                            <el-date-picker v-model="chgLocalInfo.ghDate" format="yyyy 年 MM 月 dd 日"
                                value-format="yyyy-MM-dd" type="date" placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="更换人员" :label-width="formLabelWidth" prop="ghPersonnel" class="input-lenght">
                            <el-input v-model="chgLocalInfo.ghPersonnel" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item label="更换原因" :label-width="formLabelWidth" prop="ghCause" class="input-lenght">
                            <el-input v-model="chgLocalInfo.ghCause" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item class="df" :label-width="formLabelWidth">
                            <el-button class="btn" :disabled="submitFlag" @click="submitForm()">提交</el-button>
                            <el-button @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-form>

                </div>

            </el-card>
        </div>

        <div class="ChgLocalList">
            <el-card class="box-card">
                <div slot="header" class="clearfix">
                    <el-form :inline="true" class="demo-form-inline" :model="chgLocalForm" ref="chgLocalForm">
                        <el-form-item label="仪器名称">
                            <el-input v-model="chgLocalForm.deviceRealName" placeholder="请输入仪器名称查询"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button class="btn" @click="handleSearch" icon="el-icon-search">查询</el-button>
                            <el-button @click="handleSearchReset" icon="el-icon-refresh-right">重置</el-button>
                        </el-form-item>
                    </el-form>
                </div>

                <!-- 列表 -->
                <el-table :data="chgLocalList" border style="width: 100%"
                    :header-cell-style="{ background: '#eee', fontWeight: 700, color: '#606266' }">
                    <el-table-column prop="seq" label="序号" align="center" width="50px">
                    </el-table-column>
                    <el-table-column prop="deviceOneName" label="更换仪器1" align="center">
                    </el-table-column>
                    <el-table-column prop="deviceTwoName" label="更换仪器2" align="center">
                    </el-table-column>
                    <el-table-column prop="ghDate" label="更换日期" align="center"> </el-table-column>
                    <el-table-column prop="ghPersonnel" label="更换人员" align="center"> </el-table-column>
                    <el-table-column prop="ghCause" label="更换原因" align="center">
                    </el-table-column>
                    <el-table-column label="操作" align="center" width="180">
                        <template slot-scope="scope">
                            <el-button type="text" size="mini" title="详情" @click="handleDetail(scope.row)">详情</el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <!-- 分页 -->
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="chgLocalForm.pageNum" :page-sizes="[5, 10, 20, 50, 100]"
                    :page-size="chgLocalForm.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>

            </el-card>
        </div>

    </div>
</template>

<script>
import { serchDeviceChgLog, chgDeviceLocal } from '@/api/deviceChgLocal.js'
import { getDevice } from '@/api/device.js'
export default {
    data() {
        return {
            deviceCode: null,
            chgLocalInfo: {
                id: null,
                deviceOneCode: null,
                deviceOneName: null,
                oneOldWhCode: null,
                oneWhCode: null,
                oneOldWhCodeName: null,
                oneWhCodename: null,
                oneOldLocal: null,
                oneLocal: null,
                deviceTwoCode: null,
                deviceTwoName: null,
                twoOldWhCode: null,
                twoWhCode: null,
                twoOldWhCodeName: null,
                twoWhCodename: null,
                twoOldLocal: null,
                twoLocal: null,
                ghDate: null,
                ghPersonnel: null,
                ghCause: null,
                recordTime: null
            },
            formLabelWidth: "150px",
            rules: {
                deviceOneCode: [{ required: true, message: '请扫码录入设备条码' }],
                deviceOneName: [{ required: true }],
                oneOldLocal: [{ required: true }],
                deviceTwoCode: [{ required: true, message: '请扫码录入设备条码' }],
                deviceTwoName: [{ required: true }],
                twoOldLocal: [{ required: true }],
                ghDate: [{ required: true, message: '请选择更换日期' }],
                ghPersonnel: [{ required: true, message: '请填写更换人员' }],
                ghCause: [{ required: true, message: '请输入更换原因' }],
            },
            submitFlag: false,// 是否提交中,
            keyOneDownTime: 0,
            keOneUpTime: 0,
            keyTwoDownTime: 0,
            keTwoUpTime: 0,
            chgLocalList: [], //列表
            chgLocalForm: {
                deviceRealName: '',
                pageNum: 1,
                pageSize: 10
            },
            total: 0

        }
    },
    mounted() {
        this.onAuxCodeRef()
        this.serchDeviceChgLog()

    },
    methods: {
        onAuxCodeRef() {
            this.$nextTick(() => {
                this.$refs.auxCodeRef.focus()
            })
        },


        submitForm() {
            this.$refs.chgLocalInfo.validate((valid) => {
                if (valid) {
                    if (this.chgLocalInfo.deviceOneCode === this.chgLocalInfo.deviceTwoCode) {
                        this.$message.warning('仪器条码重复，不能进行位置更换！')
                    } else {
                        this.chgLocalInfo.oneLocal = this.chgLocalInfo.twoOldLocal
                        this.chgLocalInfo.twoLocal = this.chgLocalInfo.oneOldLocal
                        this.chgLocalInfo.oneWhCode = this.chgLocalInfo.twoOldWhCode
                        this.chgLocalInfo.oneWhCodename = this.chgLocalInfo.twoOldWhCodeName
                        this.chgLocalInfo.twoWhCode = this.chgLocalInfo.oneOldWhCode
                        this.chgLocalInfo.twoWhCodename = this.chgLocalInfo.oneOldWhCodeName
                        chgDeviceLocal(this.chgLocalInfo).then(res => {
                            if (res.code === '000000') {
                                this.$message.success('位置更换成功！')
                            }
                            this.$refs.chgLocalInfo.resetFields()
                            this.submitFlag = false
                            this.serchDeviceChgLog()
                        })
                    }

                } else {
                    return false
                }
            })
        },
        handleReset() {
            this.$refs.chgLocalInfo.resetFields()
        },

        //设备1扫码
        courierOneEnterDown() {
            this.keyOneDownTime = new Date().getTime()
        },
        courierOneEnterUp() {
            this.keOneUpTime = new Date().getTime()
            if (this.keOneUpTime - this.keyOneDownTime < 30) {
                this.loadOneDeviceInfo()
            } else {
                this.loadOneDeviceInfo()
            }
        },
        loadOneDeviceInfo() {
            this.deviceCode = this.chgLocalInfo.deviceOneCode
            getDevice(this.deviceCode).then(res => {
                if (res.code === '000000') {
                    const { deviceCode, deviceRealName, whCode, whCodeName, location } = res.t
                    this.chgLocalInfo.deviceOneCode = deviceCode
                    this.chgLocalInfo.deviceOneName = deviceRealName
                    this.chgLocalInfo.oneOldLocal = location
                    this.chgLocalInfo.oneOldWhCode = whCode
                    this.chgLocalInfo.oneOldWhCodeName = whCodeName
                }
            })
        },

        //设备2扫码
        courierTwoEnterDown() {
            this.keyTwoDownTime = new Date().getTime()
        },
        courierTwoEnterUp() {
            this.keTwoUpTime = new Date().getTime()
            if (this.keTwoUpTime - this.keyTwoDownTime < 30) {
                this.loadTwoDeviceInfo()
            } else {
                this.loadTwoDeviceInfo()
            }
        },
        loadTwoDeviceInfo() {
            this.deviceCode = this.chgLocalInfo.deviceTwoCode
            getDevice(this.deviceCode).then(res => {
                if (res.code === '000000') {
                    const { deviceCode, deviceRealName, whCode, whCodeName, location } = res.t
                    this.chgLocalInfo.deviceTwoCode = deviceCode
                    this.chgLocalInfo.deviceTwoName = deviceRealName
                    this.chgLocalInfo.twoOldLocal = location
                    this.chgLocalInfo.twoOldWhCode = whCode
                    this.chgLocalInfo.twoOldWhCodeName = whCodeName
                }
            })
        },


        //列表
        handleSizeChange(val) {
            this.chgLocalForm.pageSize = val;
            this.chgLocalForm.pageNum = 1;
            this.serchDeviceChgLog();
        },
        handleCurrentChange(val) {
            this.chgLocalForm.pageNum = val;
            this.serchDeviceChgLog();
        },
        //查询
        serchDeviceChgLog() {
            serchDeviceChgLog(this.chgLocalForm).then(res => {
                if (res.code === '000000') {
                    this.chgLocalList = res.t.list
                    this.total = res.t.total
                    let count = 1;
                    this.chgLocalList.forEach((item) => {
                        item.seq = count++
                    })
                }

            })

        },

        //详情
        handleDetail({ id }) {
            this.$router.push({
                name: 'whManage-chgLocal-detail',
                params: {
                    id
                }
            })
        },

        //搜索
        handleSearch() {
            this.serchDeviceChgLog(this.chgLocalForm);
        },
        //重置
        handleSearchReset() {
            this.chgLocalForm = {};
            this.serchDeviceChgLog(this.chgLocalForm);
        },

    }
}

</script>


<style lang="scss" scoped>
.CreateAndEdit {
    .el-card {
        position: relative;
        text-align: left;

        .clearfix {
            text-align: left;
            font-size: 16px;
        }

        .cha {
            position: absolute;
            top: 0px;
            right: 5px;
            font-size: 30px;
        }

        .cha:hover {
            cursor: pointer;
            color: #006DC9;
        }
    }

    .el-form-item {
        width: 80%;
    }

    .df {
        text-align: left;
    }

    .input-lenght {
        width: 500px;
    }

}

.clearfix {
    text-align: left;
}
</style>